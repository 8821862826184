import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import restClient from '../restClient';
import Input from '../components/Input';
import InputPassword from '../components/InputPassword';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import CountdownTimer from '../components/CountdownTimer';

const ResetPassword = () => {
  const [step, setStep] = useState(0);
  const [username, setUsername] = useState('');
  const [otpCode, setOtpCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSendOtp = async () => {
    
    if (!username.trim()) {
      toast.error('نام کاربری را وارد کنید.');
      return;
    }
    
    setIsLoading(true);
    try {
      await restClient.get('/send-otp', { params: {username: username.trim()} });
      toast.success('کد یکبار مصرف به ایمیل شما ارسال شد.', { duration: 3000 });
      setStep(1);
    } catch (error) {
      toast.error(error.response?.data || 'ارتباط برقرار نیست.');
      console.error('Sending OTP failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async (e) => {

    if (!newPassword || !confirmPassword) {
      toast.error('ورودی‌ها را پر کنید.');
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error('مقدارها با هم تطابق ندارند.');
      return;
    }

    setIsLoading(true);
    try {
      const response = await restClient.post('/reset-password', { username, newPassword, otpCode });
      toast.success(response.data);
      setTimeout(() => navigate('/login'), 250);
    } catch (error) {
      toast.error(error.response?.data || 'ارتباط برقرار نیست.');
      console.error('Password reset failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Render form based on current step
  const renderForm = () => {
    switch (step) {
      case 0:
        return (
          <>
            <Input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              id="username"
              placeholder="نام کاربری"
              autoComplete="username"
            />
            <div className='mt-2'/>
            <Button isLoading={isLoading} onClick={handleSendOtp} loadingText='در حال ارسال...'>ارسال</Button>
            <Link className="text-center mt-6 caret-transparent text-sm text-neutral-700 text-l hover:text-[#7BA7E6] hover:border-[#7BA7E6] active:text-[#6093D8] active:border-[#6093D8]" to="/login">صفحه ورود</Link>
          </>
        );
      case 1:
        return (
          <>
            <Input
              value={otpCode}
              onChange={(e) => setOtpCode(e.target.value)}
              id="otpCode"
              placeholder="کد یکبار مصرف"
            />
            <div className='mt-2' />
            <Button onClick={() => { !otpCode.trim() ? toast.error('کد یکبار مصرف را وارد کنید.') : setStep(2) }}>تایید</Button>
            <CountdownTimer className='text-center mt-6'>
              <div className='flex justify-center mt-6'>
                <Link className="mx-8 sm:mr-4 caret-transparent text-sm text-neutral-700 text-l hover:text-[#7BA7E6] hover:border-[#7BA7E6] active:text-[#6093D8] active:border-[#6093D8]" to="/login">بازگشت</Link>
              </div>
            </CountdownTimer>
          </>
        );
      case 2:
        return (
          <>
            <InputPassword
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              id="password"
              placeholder="کلمه‌ عبور جدید"
              autoComplete="new-password"
            />
            <Input
              type='password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              id="confirmPassword"
              placeholder="تکرار کلمه عبور"
              autoComplete="new-password"
              className="my-3"
            />
            <Button onClick={handleResetPassword} isLoading={isLoading} loadingText='در حال بازنشانی...'>بازنشانی</Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <div className="shadow-xl border border-slate-100 rounded-2xl m-1">
        <div className="flex flex-col sm:flex-row justify-center">
          {/* Right side */}
          <img className="caret-transparent mr-0 mx-auto w-44 rounded-tr-2xl sm:rounded-br-2xl" src="../../forget.png" alt="Forget Password" />

          {/* Left side */}
          <main className="flex flex-col items-center">
            <h1 className="text-center text-xl mb-8 mt-4 text-neutral-700">بازنشانی کلمه عبور</h1>
            <form onSubmit={(e) => e.preventDefault()} className="flex flex-col mt-4 ml-8 mr-8 mb-6 sm:mr-4">
              {renderForm()}
            </form>
          </main>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
