import React, { useState, useEffect } from "react";

const CountdownTimer = ({ children, className , value = 30 * 60 }) => {
  // Set the initial time for the countdown (30 minutes = 1800 seconds)
  const [timeLeft, setTimeLeft] = useState(value);

  useEffect(() => {
    if (timeLeft === 0) return;

    // Set up the timer interval to decrease the timeLeft by 1 every second
    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // Clean up the interval when the component unmounts or when timeLeft changes
    return () => clearInterval(timerId);
  }, [timeLeft]);

  // Function to format the time in minutes and seconds
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div>
      {timeLeft ? (
        <div className={`${className}`}>{formatTime(timeLeft)}</div>
      ) : (
        <div>{ children }</div>
      )}
    </div>
  );
};

export default CountdownTimer;
