import React, { useContext } from "react";
import { useSwipeable } from "react-swipeable";
import DateObject from "react-date-object";
import { AppContext } from "../contexts/AppContext";
import { useModal } from '../contexts/ModalContext';

export const DateSwiper = ({ children }) => {
  const { setDate } = useContext(AppContext);
  const { isModalOpen } = useModal();

  const handlers = useSwipeable({
    onSwipedRight: () => {
      if (isModalOpen()) return;
      setDate((prevDate) => {
        const nextDate = new DateObject(prevDate).add(1, "day");
        return nextDate;
      });
    },
    onSwipedLeft: () => {
      if (isModalOpen()) return;
      setDate((prevDate) => {
        const previousDate = new DateObject(prevDate).subtract(1, "day");
        return previousDate;
      });
    },
    delta: 125,
    touchAngle: 20,
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: true, // For desktop
  });

  return (
    <div {...handlers} className="flex-grow flex flex-col" style={{ touchAction: 'none' }}>
      {children}
    </div>
  );
};
