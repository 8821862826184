import React from 'react';

const ButtonSubmit = ({ isLoading, children, loadingText = 'در جال اجرا...' }) => {
    return (<button type="submit" className={`select-none py-2 mt-2 text-center w-full rounded-3xl bg-[#86b4f0] hover:bg-[#7BA7E6] active:bg-[#6093D8] text-lg text-slate-50 ${isLoading ? 'cursor-not-allowed' : ''}`} disabled={isLoading}>
        {isLoading ? (
            <div className="flex items-center justify-center gap-2">
                <div className="w-6 h-6 border-2 border-white border-solid rounded-full border-t-transparent animate-spin"></div>
                <div>{loadingText}</div>
            </div>
        ) : (children)}
    </button>
    );
};

export default ButtonSubmit;
