import React from 'react';

const InputEmail = ({ value, onChange, placeholder = 'example@domain.com', disabled = false }) => {
    return (
        <input
            type="email"
            className="text-zinc-600 font-sans text-sm border border-zinc-300 rounded-3xl outline-none px-4 py-3 hover:border-zinc-400 focus:border-[#5acef5] focus:ring-1 focus:ring-[#5acef5]"
            style={{ direction: 'ltr' }}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            id="addEmail"
            autoComplete="email"
            disabled={disabled}
        />
    );
};

export default InputEmail;
