import React, { createContext, useContext, useState, useCallback, useMemo, useEffect } from 'react';

const ModalContext = createContext(null);

export const ModalProvider = ({ children }) => {
  const [activeModal, setActiveModal] = useState(null);

  const openModal = useCallback((modalId) => {
    setActiveModal(modalId);
  }, []);

  const closeModal = useCallback(() => {
    setActiveModal(null);
  }, []);

  const isModalOpen = useCallback(() => {
    return activeModal !== null;
  }, [activeModal]);

  const isTopModal = useCallback((modalId) => {
    return activeModal === modalId;
  }, [activeModal]);

  const contextValue = useMemo(() => ({
    openModal,
    closeModal,
    isModalOpen,
    isTopModal,
  }), [openModal, closeModal, isModalOpen, isTopModal]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && activeModal) {
        closeModal();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeModal, closeModal]);

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
