import React, { useEffect, useState } from "react";
import Modal from "../components/Modal";
import ButtonClose from "../components/ButtonClose";
import ConnectionTab from "../components/ConnectionTab";
import Badge from "../components/Badge";
import { useModal } from "../contexts/ModalContext";
import toast from "react-hot-toast";
import restClient from '../restClient';
import SearchConnection from "../components/SearchConnection";

const TabHeader = ({index, className, children, activeTab, setActiveTab}) => {
  return (
  <div className={`rounded-t-md p-2 flex-1 cursor-pointer text-center flex gap-1 justify-center items-center ${className} ${activeTab === index ? 'border-b-2 border-blue-500 text-blue-500' : 'hover:bg-gray-100'}`} onClick={() => setActiveTab(index)}>
    {children}
  </div>
  );
}

const ConnectionModal = ({ id = "connectionModal" }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [connections, setConnections] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isTopModal } = useModal();

  const getConnections = async ({ status }) => {
    setIsLoading(true);
    try {
      const response = await restClient.get('/connections', { params: { status } });
      return response.data;
    } catch (error) {
      toast.error(error.response?.data || 'ارتباط برقرار نیست.');
      return []
    } finally {
      setIsLoading(false);
    }
  };

  const refreshConnections = () => {
    getConnections({ status: 'accepted' }).then(setConnections);
    getConnections({ status: 'pending' }).then(setInvitations);
  }

  useEffect(() => {
    if (isTopModal(id)) {
      refreshConnections();
    }
  }, [isTopModal]);
  
  return (
    <Modal id={id} className="flex flex-col">
      <SearchConnection className='mb-2' onRefresh={refreshConnections}/>
      <div className="h-11 flex justify-center border-b mb-1">
        <TabHeader index={0} activeTab={activeTab} setActiveTab={setActiveTab}>
          <h2>دعوت‌نامه‌ها</h2>
          <Badge>{invitations.length}</Badge>
        </TabHeader>
        <TabHeader index={1} activeTab={activeTab} setActiveTab={setActiveTab}>
          <h2>همراهان</h2>
          <Badge>{connections.length}</Badge>
        </TabHeader>
      </div>
      {activeTab == 0 && <ConnectionTab className='mb-1' onRefresh={refreshConnections} connections={invitations} isLoading={isLoading} emptyText='دعوت‌نامه‌ای وجود ندارد.' /> }
      {activeTab == 1 && <ConnectionTab className='mb-1' onRefresh={refreshConnections} connections={connections} isLoading={isLoading} emptyText='همراهی وجود ندارد.' /> }
      <ButtonClose className="w-full mt-auto">بستن</ButtonClose>
    </Modal>
  );
};

export default ConnectionModal;
