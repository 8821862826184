import { useState, useEffect, useRef } from "react";
import { COLORS } from "../constants";
import { useModal } from "../contexts/ModalContext";

const ColorPicker = ({ selectedColor, onChange }) => {
  const { openModal, closeModal, isTopModal } = useModal();
  const menuRef = useRef(null);
  const modalId = "colorPickerModal";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeModal]);

  const handleColorClick = (color) => {
    onChange({ target: { value: color } });
    closeModal();
  };

  const toggleMenu = () => {
    if (isTopModal(modalId)) {
      closeModal();
    } else {
      openModal(modalId);
    }
  };


  return (
    <div className="relative caret-transparent">
      <div className="hidden md:flex gap-x-2">
        {COLORS.map((color) => (
          <div
            key={color}
            onClick={() => handleColorClick(color)}
            className={`w-9 h-9 rounded-3xl cursor-pointer ${
              selectedColor === color ? "ring-2 ring-zinc-400" : ""
            }`}
            style={{ backgroundColor: color }}
          />
        ))}
      </div>
      <div className="flex md:hidden items-center justify-center">
        <div
          onClick={toggleMenu}
          className="w-9 h-9 rounded-3xl cursor-pointer hover:ring-1 hover:ring-zinc-200"
          style={{ backgroundColor: selectedColor }}
        />
        {isTopModal(modalId) && (
          <div
            ref={menuRef}
            className="absolute flex flex-col bottom-full mb-2 bg-white justify-evenly items-center w-12 h-fit gap-2 pt-2 pb-2 border rounded-3xl shadow-md"
          >
            {COLORS.filter((color) => color !== selectedColor).map((color) => (
              <div
                key={color}
                onClick={() => handleColorClick(color)}
                className="w-9 h-9 rounded-3xl cursor-pointer hover:ring-1 hover:ring-zinc-200"
                style={{ backgroundColor: color }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorPicker;
