import React, { useState } from "react";
import toast from "react-hot-toast";
import restClient from "../restClient";
import ButtonAction from "./ButtonAction";
import Avatar from "./Avatar";

const ConnectionItem = ({ connection, onRefresh, className }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [actionResult, setActionResult] = useState("");
  const { otherUsername, isSent, isInvitation } = connection;

  const acceptConnection = async () => {
    setIsLoading(true);
    try {
      const response = await restClient.post(`/connections/accept`, { otherUsername });
      toast.success(response.data);
      setActionResult("قبول شد");
    } catch (error) {
      toast.error(error.response?.data || "ارتباط برقرار نیست.");
    } finally {
      setIsLoading(false);
    }
  };

  const rejectConnection = async () => {
    setIsLoading(true);
    try {
      const response = await restClient.post(`/connections/reject`, { otherUsername });
      toast.success(response.data);
      setActionResult("رد شد");
    } catch (error) {
      toast.error(error.response?.data || "ارتباط برقرار نیست.");
    } finally {
      setIsLoading(false);
    }
  };

  const cancelConnection = async () => {
    setIsLoading(true);
    try {
      const response = await restClient.post(`/connections/cancel`, { otherUsername });
      toast.success(response.data);
      setActionResult("لغو شد");
    } catch (error) {
      toast.error(error.response?.data || "ارتباط برقرار نیست.");
    } finally {
      setIsLoading(false);
    }
  };

  const deleteConnection = async () => {
    setIsLoading(true);
    try {
      const response = await restClient.post(`/connections/delete`, { otherUsername });
      toast.success(response.data);
      setActionResult("حذف شد");
    } catch (error) {
      toast.error(error.response?.data || "ارتباط برقرار نیست.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <li className={`rounded px-2 py-1 flex flex-row-reverse gap-2 items-center ${className}`}>
      <Avatar username={otherUsername} className='max-w-52'/>
      <div className="flex gap-1 ml-auto">
        {actionResult ? 
          <span className="text-sm text-zinc-600">{actionResult}</span> 
        : 
          isInvitation ?
            isSent ?
              <ButtonAction caption='لغو' isLoading={isLoading} onClick={async () => {await cancelConnection(); onRefresh();}} className="bg-teal-600 hover:bg-teal-500 active:bg-teal-700" />
            :
              <>
                <ButtonAction caption='قبول' isLoading={isLoading} onClick={async () => {await acceptConnection(); onRefresh();}} className="bg-green-600 hover:bg-green-500 active:bg-green-700"/>
                <ButtonAction caption='رد' isLoading={isLoading} onClick={async () => {await rejectConnection(); onRefresh();}} className="bg-red-600 hover:bg-red-500 active:bg-red-700"/>
              </>
            :
              <ButtonAction caption='حذف' isLoading={isLoading} onClick={async () => {await deleteConnection(); onRefresh();}} className="bg-gray-600 hover:bg-gray-500 active:bg-gray-700" />
        }
      </div>
    </li>
  );
};

export default ConnectionItem;
