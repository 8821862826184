import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useModal } from '../contexts/ModalContext';
import ModalForm from '../components/ModalForm';
import Input from '../components/Input';
import InputPassword from '../components/InputPassword';
import ButtonSubmit from '../components/ButtonSubmit';
import ButtonClose from '../components/ButtonClose';
import restClient from '../restClient';


const ChangePasswordModal = ({ id = 'changePasswordModal' }) => {
    const { closeModal } = useModal();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            toast.error('مقدارها با هم تطابق ندارند.');
            return;
        }

        setIsLoading(true);
        try {
            const response = await restClient.post('/change-password', { currentPassword, newPassword, });
            toast.success(response.data);
            closeModal();
        } catch (error) {
            toast.error(error.response?.data || 'ارتباط برقرار نیست.');
        }
        setIsLoading(false);
    };

    return (
        <ModalForm id={id} title='تغییر کلمه عبور' onSubmit={handleSubmit}>
            <Input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                id="currentPassword"
                placeholder="کلمه عبور فعلی"
                autoComplete="current-password"
            />
            <InputPassword
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                id="newPassword"
                placeholder="کلمه عبور جدید"
                autoComplete="new-password"
            />
            <Input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                id="confirmPassword"
                placeholder="تکرار کلمه عبور جدید"
                autoComplete="new-password"
            />
            <ButtonSubmit isLoading={isLoading} loadingText="در حال تغییر...">تأیید</ButtonSubmit>
            <ButtonClose>انصراف</ButtonClose>
        </ModalForm>
    );
};

export default ChangePasswordModal;
