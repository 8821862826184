import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import restClient from '../restClient';

import Input from '../components/Input';
import InputPassword from '../components/InputPassword';
import ButtonSubmit from '../components/ButtonSubmit';
import Checkbox from '../components/Checkbox';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const navigate = useNavigate();
  const toggleVisibility = () => setVisible(!visible);

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const response = await restClient.post('/login', { username, password, rememberMe, });
      toast.success(response.data);
      setTimeout(() => navigate('/'), 250);
    } catch (error) {
      toast.error(error.response?.data || 'ارتباط برقرار نیست.');
      console.error('Login failed: ', error);
    }
    setIsLoading(false);
  };

  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <div className="shadow-xl border border-slate-100 rounded-2xl m-1">
        <div className="flex flex-col sm:flex-row justify-center">
          {/* Right side */}
          <img className="caret-transparent mr-0 mx-auto w-44 sm:w-48 rounded-tr-2xl sm:rounded-br-2xl" src="../../login.png" alt="Login" />

          {/* Left side */}
          <main className="flex flex-col items-center">
            <h1 className="text-center text-xl mb-8 mt-4 text-neutral-700">صفحه ورود</h1>
            <form onSubmit={(e) => { e.preventDefault(); handleLogin(); }} className="flex flex-col gap-4 mx-8 mb-6 sm:mr-4">
              <Input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                id="username"
                placeholder="نام کاربری"
                autoComplete="username"
              />
              <InputPassword
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                visible={visible}
                toggleVisibility={toggleVisibility}
                id="password"
                placeholder="کلمه‌ عبور"
                autoComplete="current-password"
              />
              <Checkbox
                id="rememberMe"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
                label="مرا به‌خاطر بسپار"
              />
              <ButtonSubmit isLoading={isLoading} loadingText="در حال ورود...">ورود به برنامه</ButtonSubmit>
              <div className='mt-2 flex justify-between mx-4'>
                <Link className="caret-transparent text-sm text-neutral-700 text-l hover:text-[#7BA7E6] hover:border-[#7BA7E6] active:text-[#6093D8] active:border-[#6093D8]" to="/register">ثبت‌نام</Link>
                <Link className="caret-transparent text-sm text-neutral-700 text-l hover:text-[#7BA7E6] hover:border-[#7BA7E6] active:text-[#6093D8] active:border-[#6093D8]" to="/reset-password">بازنشانی کلمه عبور</Link>
              </div>
            </form>
          </main>
        </div>
      </div>
      <div className='text-zinc-500 text-xs mt-6'>ورود شما به معنای پذیرفتن <Link className='text-[#86b4f0]' to='https://listan.ir/privacy'>سیاست‌های حریم خصوصی</Link> است.</div>
    </div>
  );
};

export default Login;
