import React from 'react';

const Checkbox = ({ id, checked, onChange, label }) => {
    return (
        <div className="cursor-pointer flex items-center gap-2 pr-1 caret-transparent select-none">
            <input
                type="checkbox"
                id={id}
                checked={checked}
                onChange={onChange}
                className="cursor-pointer appearance-none h-5 w-5 border rounded-full checked:border-none checked:bg-[#86b4f0] hover:border-zinc-300"
            />
            <label htmlFor={id} className="cursor-pointer text-sm text-neutral-700">{label}</label>
        </div>
    );
};

export default Checkbox;
