import React, { useState } from 'react'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import ButtonAction from './ButtonAction';
import restClient from '../restClient';
import toast from 'react-hot-toast';

const ButtonInvite =({className, otherUsername, onInvite}) => {

  const [isLoading, setIsLoading] = useState(false);

  const handleSendConnection = async() => {
    setIsLoading(true);
    try {
      const response = await restClient.post('/connections', {otherUsername});
      if(onInvite) onInvite();
      toast.success(response.data);
    } catch (error) {
      toast.error(error.response?.data || 'ارتباط برقرار نیست.');
      console.error('Invitation failed: ', error);
    }finally{
      setIsLoading(false);
    }
  }

  return <ButtonAction className={`bg-sky-500 text-white rounded px-2 ${className}`} onClick={handleSendConnection} isLoading={isLoading} caption='دعوت' />
}

const SearchConnection = ({className, onRefresh}) => {
  const [query, setQuery] = useState('')
  return (
    <div className={`flex ${className}`} style={{ direction: 'ltr' }}>
        <MagnifyingGlassIcon className={`w-7 text-zinc-600 ${!query ? 'visible' : 'hidden' }`} />
        <XMarkIcon className={`cursor-pointer w-7 ${query ? 'visible' : 'hidden' }`} onClick={()=>setQuery('')}/>
        <input value={query} onChange={(e) => setQuery(e.target.value)} className='text-sm font-sans outline-none px-1 w-full' />
        <ButtonInvite className={`h-[18px] ml-1 mr-2 flex self-center items-center ${query ? 'visible' : 'invisible' }`} otherUsername={query} onInvite={()=>{setQuery(''); onRefresh()}} />
    </div>
  )
}

export default SearchConnection;
