import React, { useContext, useEffect, useState } from "react";
import Modal from "../components/Modal";
import ButtonClose from "../components/ButtonClose";
import { AppContext } from "../contexts/AppContext";
import { useModal } from "../contexts/ModalContext";
import toast from "react-hot-toast";
import restClient from '../restClient';
import ShareItem from "../components/ShareItem";
import Spinner from "../components/Spinner";


const ShareModal = ({ id = "shareModal", onAction }) => {
  const [shareableUsers, setShareableUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const {selectedNote} = useContext(AppContext);
  const { isTopModal } = useModal();

  const getShareableUsers = async () => {
    setIsLoading(true);
    try {
      const response = await restClient.get('/share', { params: { noteId: selectedNote.id } });
      return response.data;
    } catch (error) {
      toast.error(error.response?.data || 'ارتباط برقرار نیست.');
      return []
    } finally {
      setIsLoading(false);
    }
  };

  const refreshShareableUsers = () => {
    getShareableUsers().then(setShareableUsers);
  }

  useEffect(() => {
    if (isTopModal(id)) {
      refreshShareableUsers();
    }
  }, [isTopModal]);
  
  return (
    <Modal id='shareModal' title='اشتراک‌گذاری'>
        <div className={`flex flex-col max-h-72 h-72`}>
            {isLoading ? <Spinner className='mt-2' text="در حال دریافت..." /> 
            :
            <ul className="flex flex-col overflow-y-auto">
                {shareableUsers.length === 0 && <div className="text-gray-400 text-sm text-center mt-2">همراهی برای اشتراک‌گذاری نیست.</div>}
                {shareableUsers.map((shareableUser, index) => (<ShareItem key={index} noteId={selectedNote.id} shareableUser={shareableUser} onAction={onAction} className={`${index % 2 ? 'bg-gray-50' : 'bg-white'}`}/>))}
            </ul>
            }
            </div>
        <ButtonClose className="w-full mt-auto">بستن</ButtonClose>
    </Modal>
  );
};

export default ShareModal;
