import React, { useEffect, useState } from 'react';
import Spinner from './Spinner';
import ConnectionItem from './ConnectionItem';

const ConnectionTab = ({ isLoading, onRefresh, connections, className, emptyText }) => {
  return (
      <div className={`flex flex-col ${className} max-h-72 h-72`}>
        {isLoading ? <Spinner className='mt-2' text="در حال دریافت..." /> 
        :
          <ul className="flex flex-col overflow-y-auto">
            {connections.length === 0 && <div className="text-gray-400 text-sm text-center mt-2">{emptyText}</div>}
            {connections.map((connection, index) => (<ConnectionItem onRefresh={onRefresh} key={connection.id} connection={connection} className={`${index % 2 ? 'bg-gray-50' : 'bg-white'}`}/>))}
          </ul>
        }
      </div>
  );
};

export default ConnectionTab;
