import React, { useEffect, useState } from 'react';
import { useModal } from '../contexts/ModalContext';
import toast from 'react-hot-toast';
import restClient from '../restClient';

import Modal from '../components/Modal';
import ButtonClose from '../components/ButtonClose';
import Spinner from '../components/Spinner';
import SessionItem from '../components/SessionItem';

const SessionModal = ({ id = 'sessionModal' }) => {
  const { isTopModal } = useModal();
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSessions = async () => {
    setLoading(true);
    try {
      const response = await restClient.get('/sessions');
      setSessions(response.data);
    } catch (error) {
      toast.error(error.response?.data || 'ارتباط برقرار نیست.');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isTopModal(id)) getSessions();
  }, [isTopModal]);

  const handleDeleteSession = async (sessionId) => {
    try {
      const response = await restClient.delete(`/sessions/${sessionId}`);
      toast.success(response.data);
      await getSessions();
    } catch (error) {
      toast.error(error.response?.data || 'ارتباط برقرار نیست.');
    }
  };

  if (!isTopModal(id)) return null;

  return (
    <Modal id={id} title="نشست‌های فعال">
      <div className="flex flex-col gap-4">
        {loading ? (
          <Spinner text="در حال دریافت..." />
        ) : (
          <ul className="flex flex-col gap-4 max-h-72 overflow-y-auto pb-1.5">
            {sessions.map((session) => (<SessionItem key={session.id} session={session} handleDeleteSession={handleDeleteSession} />))}
          </ul>
        )}
        <ButtonClose>بستن</ButtonClose>
      </div>
    </Modal>
  );
};

export default SessionModal;
