import React from "react";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthRoute from "./routes/AuthRoute";
import UnauthRoute from "./routes/UnauthRoute";
import ResetPassword from "./pages/ResetPassword";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <AuthRoute>
              <Home />
            </AuthRoute>
          }
        />
        <Route
          path="/register"
          element={
            <UnauthRoute>
              <Register />
            </UnauthRoute>
          }
        />
        <Route
          path="/login"
          element={
            <UnauthRoute>
              <Login />
            </UnauthRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            <UnauthRoute>
              <ResetPassword />
            </UnauthRoute>
          } 
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
