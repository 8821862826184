import React, { useState, forwardRef } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

const InputPassword = forwardRef(({
    value,
    onChange,
    className,
    id,
    placeholder,
    autoComplete,
}, ref) => {
    const [visible, setVisible] = useState(false);

    const toggleVisibility = () => setVisible(!visible);

    return (
        <div className="flex items-center">
            <button
                type="button"
                className="outline-none text-gray-500 ml-[-30px] pr-2 z-10"
                onClick={toggleVisibility}
            >
                {visible ? (
                    <EyeSlashIcon className="h-5 w-5" />
                ) : (
                    <EyeIcon className="h-5 w-5" />
                )}
            </button>
            <input
                ref={ref}
                type={visible ? 'text' : 'password'}
                value={value}
                onChange={onChange}
                className={`border rounded-3xl outline-none pr-8 pl-6 py-3 flex-grow w-full hover:border-zinc-300 ${className}`}
                id={id}
                style={{ direction: 'ltr' }}
                placeholder={placeholder}
                autoComplete={autoComplete}
            />
        </div>
    );
});

export default InputPassword;
