import React, { useEffect, useState, createContext } from "react";
import { DateObject } from "react-multi-date-picker";
import toast from "react-hot-toast";
import restClient from "../restClient";
import gregorian from "react-date-object/calendars/gregorian";
import gregorian_en from "react-date-object/locales/gregorian_en";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [date, setDate] = useState(new DateObject());
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [onEdit, setOnEdit] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isPending, setIsPending] = useState(false);

  const insertNote = async (newNote) => {
    try {
      setIsPending(true);
      return await restClient.post("/notes", newNote);
    } catch (error) {
      toast.error(error.response?.data || 'ارتباط برقرار نیست.');
      console.error("Error inserting note:", error);
    } finally {
      setIsPending(false);
    }
  };

  const deleteNote = async (noteId) => {
    try {
      setIsPending(true);
      return await restClient.delete(`/notes?id=${noteId}`);
    } catch (error) {
      toast.error(error.response?.data || 'ارتباط برقرار نیست.');
      console.error("Error deleting note: ", error);
    } finally {
      setIsPending(false);
    }
  };

  const editNote = async (noteId, fields) => {
    try {
      setIsPending(true);
      return await restClient.patch(`/notes?id=${noteId}`, fields);
    } catch (error) {
      toast.error(error.response?.data || 'ارتباط برقرار نیست.');
      console.error("Error getting notes: ", error);
    } finally {
      setIsPending(false);
    }
  };

  const getNotes = async () => {
    try {
      setIsPending(true);
      const formattedDate = date.format("YYYY-MM-DD");
      const response = await restClient.get("/notes", { params: { date: formattedDate } });
      setNotes(response.data);
    } catch (error) {
      toast.error(error.response?.data || 'ارتباط برقرار نیست.');
      console.error("Error getting notes: ", error);
      setNotes([]);
    } finally {
      setIsPending(false);
    }
  };

  useEffect(() => {
    getNotes();
  }, [date]);

  useEffect(() => {
    const updateProgress = () => {
      if (notes.length === 0) {
        setProgress(0);
        return;
      }

      const completedNotesCount = notes.filter(note => note.completed === 1).length;
      const completionPercentage = Math.floor((completedNotesCount / notes.length) * 100);

      setProgress(completionPercentage);
    };

    updateProgress();
  }, [notes]);

  return (
    <AppContext.Provider value={{ date, setDate, notes, getNotes, selectedNote, setSelectedNote, progress, editNote, onEdit, setOnEdit, isPending, setIsPending, deleteNote, insertNote }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
