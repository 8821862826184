import React from 'react';

const InputHint = ({ enable, hints }) => {
  return (
    <div className={`bg-orange-100 ring-1 ring-orange-200 rounded-2xl transition-all duration-300 ease-in-out transform overflow-hidden ${ enable ? 'opacity-100 max-h-40 py-2 px-4 relative' : 'opacity-0 max-h-0 py-0 px-2 absolute' }`} >
      <ul className="list-none text-sm text-gray-600 list-disc list-inside">
        {hints.map((hint, index) => (
          <li className="relative pr-3 before:content-['•'] before:absolute before:right-0 before:top-[-1px] before:text-lg before:text-orange-500 text-zinc-500" key={index} >
            {hint}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InputHint;
