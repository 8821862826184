import React from 'react';
import { useModal } from '../contexts/ModalContext';
import Modal from '../components/Modal';
import ButtonClose from '../components/ButtonClose';
import Button from '../components/Button';

const ConfirmModal = ({ id, onYes, question }) => {
  const { closeModal } = useModal();

  const handleConfirm = () => {
    onYes();
    closeModal();
  };

  return (
    <Modal id={id} title={question}>
        <div className="flex flex-col justify-center gap-4">
          <Button isLoading={false} onClick={handleConfirm}>بله</Button>
          <ButtonClose>خیر</ButtonClose>
        </div>
      </Modal>
  );
};

export default ConfirmModal;
