import Avvvatars from "avvvatars-react";
import React from "react";

const Avatar = ({ username, withText = true, className, circleSize = 35, textSize = 'text-sm' }) => {
  const size = `${circleSize * 4}px`;

  return (
    <div className={`flex items-center content-end gap-2 select-none ${className}`} style={{direction: 'ltr'}}>
      <span>
        <Avvvatars value={username} size={circleSize} />
      </span>
      {withText ? <span className={`font-sans text-left text-gray-600 overflow-hidden text-ellipsis whitespace-nowrap ${textSize}`}>{username}</span> : null}
    </div>
  );
};

export default Avatar;
