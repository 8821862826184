import React, { useEffect, useState } from 'react';
import { useModal } from '../contexts/ModalContext';
import toast from 'react-hot-toast';
import restClient from '../restClient';
import Modal from '../components/Modal';
import Spinner from '../components/Spinner';
import NotificationItem from '../components/NotificationItem';
import ButtonClose from '../components/ButtonClose';

const NotificationModal = ({ id = 'notificationModal' }) => {
  const { isTopModal } = useModal();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getNotifications = async () => {
      setLoading(true);
      try {
        const response = await restClient.get('/notifications');
        setNotifications(response.data);
      } catch (error) {
        toast.error(error.response?.data || 'ارتباط برقرار نیست.');
      }
      setLoading(false);
    };
    if (isTopModal(id)) getNotifications();
  }, [isTopModal]);

  const getDateLabel = (date) => {
    const notificationDate = new Date(date);
    notificationDate.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const timeDiff = today - notificationDate;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    return daysDiff === 0 ? 'امروز' : `${daysDiff} روز پیش`;
  };

  const handleMarkAsRead = async (notificationId) => {
    try {
      const response = await restClient.patch(`/notifications/${notificationId}/read`);
      setNotifications((prev) => prev.map((notification) => notification.id === notificationId ? { ...notification, read: 1 } : notification));
    } catch (error) {
      toast.error(error.response?.data || 'ارتباط برقرار نیست.');
    }
  };

  return (
    <Modal id={id} title="اعلان‌ها">
      <div className='flex flex-col gap-4'>
        {loading ? (<Spinner text="در حال دریافت..." />) : (
          <ul className="flex flex-col gap-4 max-h-72 overflow-y-auto pb-1.5">
            {notifications.length === 0 && (<div className="text-gray-400 text-sm text-center">اعلانی وجود ندارد.</div>)}
            {notifications.map((notification) => (<NotificationItem key={notification.id} notification={notification} handleMarkAsRead={handleMarkAsRead} getDateLabel={getDateLabel} />))}
          </ul>
        )}
        <ButtonClose>بستن</ButtonClose>
      </div>
    </Modal >
  );
};

export default NotificationModal;
