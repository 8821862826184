import React, { forwardRef } from 'react';

const Input = forwardRef(({
  type = 'text',
  value,
  onChange,
  className,
  id,
  placeholder,
  autoComplete,
}, ref) => {
  return (
    <input
      ref={ref}
      type={type}
      value={value}
      onChange={onChange}
      className={`border rounded-3xl outline-none px-6 py-3 hover:border-zinc-300 ${className}`}
      style={{ direction: 'ltr' }}
      id={id}
      placeholder={placeholder}
      autoComplete={autoComplete}
    />
  );
});

export default Input;
