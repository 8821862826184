import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../contexts/AppContext";

const CircularProgress = () => {
  const { progress } = useContext(AppContext);
  const [showTime, setShowTime] = useState(false);

  useEffect(() => {
    // Set up interval to flip the state every 10 seconds
    const interval = setInterval(() => {
      setShowTime(true);

      // Flip back after 3 seconds
      const timeout = setTimeout(() => {
        setShowTime(false);
      }, 3000);

      // Cleanup timeout when interval is reset
      return () => clearTimeout(timeout);
    }, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // Calculate the current time in HH:MM 24-hour format
  const getCurrentTime = () => {
    const now = new Date();
    return now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  };

  const getDayProgress = () => {
    const now = new Date();
    const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    const secondsInDay = 24 * 60 * 60;
    const secondsPassed = (now - startOfDay) / 1000;
    return Math.floor((secondsPassed / secondsInDay) * 100);
  };

  const progressStyle = {
    background: `conic-gradient(#42a0f5 ${progress * 3.6}deg, #bbdefb ${progress * 3.6}deg)`,
    transition: 'background 0.5s ease-in-out',
  };

  const dayProgressStyle = {
    background: `conic-gradient(#42a0f5 ${getDayProgress() * 3.6}deg, #bbdefb ${getDayProgress() * 3.6}deg)`,
    transition: 'background 0.5s ease-in-out',
  };

  return (
    <div
      className="select-none relative flex items-center justify-center w-20 h-20 rounded-full"
      style={showTime ? dayProgressStyle : progressStyle}
    >
      <div
        className="absolute flex items-center justify-center bg-white rounded-full transition-transform duration-500"
        style={{ width: '75%', height: '75%', transform: `rotateY(${showTime ? 180 : 0}deg) ${showTime ? 'scaleX(-1)' : ''}`, }}>
        <span className="cursor-default text-xl font-semibold text-[#42a0f5]">
          {showTime ? getCurrentTime() : `%${progress}`}
        </span>
      </div>
    </div>
  );
};

export default CircularProgress;
