import React from "react";

const ButtonAction = ({ className, caption, onClick, isLoading = false }) => {
    return (
      <div className={`${className} w-10 flex justify-center text-xs p-1 rounded text-white font-bold cursor-pointer transition-colors duration-150 ease-in-out`} onClick={onClick}>
        {isLoading ? <div className="w-4 h-4 border-2 border-white border-solid rounded-full border-t-transparent animate-spin" /> : <span>{caption}</span>}
      </div>
    );
  };


  export default ButtonAction;