import React from 'react';
import { EnvelopeIcon, EnvelopeOpenIcon } from '@heroicons/react/24/outline';

const NotificationItem = ({ notification, handleMarkAsRead, getDateLabel }) => {
    const { id, message, read, created_at } = notification;

    return (
        <li className={`flex flex-col gap-1 items-start bg-white rounded-lg py-2 px-3 mx-2 shadow ${read ? 'opacity-70' : 'hover:shadow-md cursor-pointer'}`} onClick={() => (!read ? handleMarkAsRead(id) : null)}>
            <div className='flex gap-2 items-end'>
                <div className="flex-shrink-0">{!read ? <EnvelopeIcon className="w-5 h-5 text-blue-500" /> : <EnvelopeOpenIcon className="w-5 h-5 text-gray-400" />}</div>
                <span className="text-xs text-gray-400">{getDateLabel(created_at)}</span>
            </div>
            <p className="text-sm text-gray-600">{message}</p>
        </li>
    );
};

export default NotificationItem;
