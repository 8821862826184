import React, { useState } from "react";
import toast from "react-hot-toast";
import restClient from "../restClient";
import ButtonAction from "./ButtonAction";
import Avatar from "./Avatar";

const ShareItem = ({ noteId, shareableUser, className, onAction }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [actionResult, setActionResult] = useState('');
  const { otherUsername, isSharedByYou } = shareableUser;

  const shareWithUser = async () => {
    setIsLoading(true);
    try {
      await restClient.post(`/share/send`, { noteId, otherUsername });
      setActionResult("ارسال شد");
    } catch (error) {
      toast.error(error.response?.data || "ارتباط برقرار نیست.");
    } finally {
      setIsLoading(false);
    }
  };

  const removeShareWithUser = async () => {
    setIsLoading(true);
    try {
      await restClient.post(`/share/unsend`, { noteId, otherUsername });
      setActionResult("لغو شد");
    } catch (error) {
      toast.error(error.response?.data || "ارتباط برقرار نیست.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <li className={`rounded px-2 py-1 flex flex-row-reverse gap-2 items-center ${className}`}>
      <Avatar username={otherUsername} className='max-w-52' />
      <div className="flex gap-1 ml-auto">
        {actionResult ? 
          <span className="text-sm text-zinc-600">{actionResult}</span> 
        : 
        !isSharedByYou ?
        <ButtonAction caption='ارسال' isLoading={isLoading} onClick={async () => {await shareWithUser(); onAction();}} className="bg-green-600 hover:bg-green-500 active:bg-green-700"/>
        :
        <ButtonAction caption='لغو' isLoading={isLoading} onClick={async () => {await removeShareWithUser(); onAction();}} className="bg-red-600 hover:bg-red-500 active:bg-red-700"/>
        }
      </div>
    </li>
  );
};

export default ShareItem;
