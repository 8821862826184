import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import restClient from "../restClient";

const AuthRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    const checkAuth = async () => {
        try {
            const response = await restClient("/user");
            setIsAuthenticated(response.status === 200);
        } catch (error) {
            setIsAuthenticated(false);
        }
    };

    useEffect(() => {
        checkAuth();
    }, []);

    if (isAuthenticated === null) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="w-32 h-32 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin"></div>
            </div>
        );
    }

    return isAuthenticated ? children : <Navigate to="/login" />;

};

export default AuthRoute;