import React from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { DateObject } from 'react-multi-date-picker';
import persian from 'react-date-object/calendars/persian';
import persian_fa from 'react-date-object/locales/persian_fa';

const SessionItem = ({ session, handleDeleteSession }) => {
    const { id, expiresAt, isCurrent } = session;
    const expirationDate = new DateObject(expiresAt).convert(persian, persian_fa).format('YYYY/MM/DD HH:mm');

    return (
        <li className={`flex items-center bg-white rounded-lg p-2 mx-2 shadow ${isCurrent ? 'border-l-4 border-blue-500' : 'hover:shadow-md'}`}>
            <div className="flex-1">
                <p className="text-sm text-gray-600 mb-1">{`شناسه: ${id}`}</p>
                <p className="text-xs text-gray-500 mb-1">{`انقضاء: ${expirationDate}`}</p>
                {isCurrent && (<span className="text-xs text-blue-500">نشست جاری</span>)}
            </div>
            {!isCurrent && (
                <button className="text-red-400 hover:text-red-500" onClick={() => handleDeleteSession(id)}>
                    <TrashIcon className="w-6 h-6" />
                </button>
            )}
        </li>
    );
};

export default SessionItem;
